import React from "react"
import Layout from "../../Components/pageComponents/contentContainer"
import { Container } from "reactstrap"

import { useStaticQuery, graphql } from "gatsby"
import CloudSolutions from "../../Components/pageComponents/cloudSolutions"
import SolutionInfoSection from "../../Components/pageComponents/solutionsInfoSection"

import style from "./styles.module.css"

export default function CloudComputing() {

  const Image = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "solutions/Cloud Computing/Cloud Computing Banner.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hybrid: file(relativePath: {eq: "solutions/Cloud Computing/Hybrid Cloud.png"}) {
        childImageSharp {
          fixed(width: 170) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      private: file(relativePath: {eq: "solutions/Cloud Computing/Private Cloud.png"}) {
        childImageSharp {
          fixed(width: 170) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      public: file(relativePath: {eq: "solutions/Cloud Computing/Public Cloud.png"}) {
        childImageSharp {
          fixed(width: 170) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iot: file(relativePath: {eq: "solutions/Cloud Computing/Internet of Things Stock.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`
  )

  const set1 = [{ name: "Public Cloud", description: "Public Cloud, unlike private cloud, is a cloud storage that is used by and is for multiple organizations." }];
  const set2 = [{ name: "Private Cloud", description: "Private Cloud is a cloud storage that delivers service for a single organization only. Resources are not shared with other organizations." }];
  const set3 = [{ name: "Hybrid Cloud", description: "Hybrid Cloud is a mixture of Public & Private Cloud and On-Premise Infrastructure Computing Environment." }];
  const description = "Embrace the era of IoT or Internet of Things by advancing to Cloud Computing. Cloud Computing let users access their data and information over the Internet. This fact allows the act of lessening hardware such as hard drive and other storages all while increasing collaboration, scalability, flexibility, reliability and efficiency.";
  return (
    <Layout image={Image.banner.childImageSharp.fluid}
      title="Cloud Computing">

      <SolutionInfoSection heading="Solutions We Offer" title="Cloud Computing" description={description}
        img={Image.iot.childImageSharp.fluid} />


      <Container
        fluid
        // className="pt-5 pb-5 pl-5 pr-5"
        style={{
          background: `var(--FSC-YELLOW)`,
          paddingTop: `2rem`,
          paddingBottom: `3rem`,
        }}
      >
        <h1 className="mx-auto mb-4 heading1 p-3 text-center">
          <span>Cloud Computing Solutions</span>
        </h1>
        <div className={style.audioVideoContainer}>
          <CloudSolutions img={Image.public.childImageSharp.fixed} info={set1} />
          <CloudSolutions img={Image.private.childImageSharp.fixed} info={set2} />
          <CloudSolutions img={Image.hybrid.childImageSharp.fixed} info={set3} />
        </div>
      </Container>

    </Layout>

  )
}