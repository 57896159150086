import React from "react"
import Img from "gatsby-image"
import style from "./styles.module.css"
import { Container } from "reactstrap"


const SolutionsInfoSection = ({ heading, title, description, img, children }) => {
  return (
    <Container
      fluid
      // className="pt-5 pb-5 pl-5 pr-5"
      style={{
        background: `rgb(243, 243, 243)`,
        paddingTop: `1.5rem`,
        paddingBottom: `3rem`,
      }}
    >
      {heading ? (
        <h1 className="mx-auto mb-4 heading1 p-3 text-center">
          <span>{heading}</span>
        </h1>
      ) : (<div style={{ marginTop: "" }} />)}

      <div className={style.solutionsInfoContainer}>
        <div className={style.solutionsInfoContent}>
          <h2>{title}</h2>
          <p>
            {description}
          </p>
          {children}
        </div>
        <div className={style.solutionsInfoContent}>
          {img ? (
            <Img fluid={img} />
          ) : (
              <div />
            )}

        </div>
      </div>
    </Container>
  )
}

export default SolutionsInfoSection